<template>

	<div class="video_wrapp" :class="{active: isActive}" v-on:mouseleave="isActive = false">

		<div class="video_card" v-if="videoTypeCard">
			<div class="video_card_title" v-if="videoCardTitle !== ''">{{ videoCardTitle }}</div>
			<div class="video_card_txt" v-if=" videoCardTxt !== ''">
				{{  videoCardTxt }}
			</div>
			<strong class="video_card_subtitle" v-if="videoCardSubtitle !== ''">{{ videoCardSubtitle }}</strong>
		</div>

		<div v-else>
		
			<div class="video_hd" v-if="videoHd">HD+</div>

			<div class="video_live" v-if="videoLive">LIVE</div>

			<div class="video_watching" v-if="videoUsersWatching">{{videoUsersWatching}} <img src="../assets/images/icons/eye.png" alt="eye"></div>

			<ul class="video_model_info" v-if="videoModelCountry || videoModelAge">
				
				<li v-if="videoStar">
          <img src="@/assets/images/icons/star-yellow.png" alt="star" width="13"/>
				</li>

				<li v-if="videoModelName"><span class="video_model_name">{{ videoModelName }},</span></li>

				<li v-if="videoModelAge"> {{ videoModelAge }} </li>

				<li v-if="videoModelCountry === 'UA'"> <img :src="require(`@/assets/images/icons/ukraine.png`)" alt="UA"></li>
				<li v-else-if="videoModelCountry === 'RU'"><img :src="require(`@/assets/images/icons/russia.png`)" alt="RU"></li>
				<li v-else-if="videoModelCountry === 'PL'"><img :src="require(`@/assets/images/icons/poland.png`)" alt="PL"></li>
				<li v-else-if="videoModelCountry === 'PL2'"><img :src="require(`@/assets/images/icons/poland2.png`)" alt="PL"></li>

			</ul>

			<div class="video_poster">
				<img :src="videoPosterSrc" :alt="videoPosterSrc">
			</div>

			<img src="@/assets/images/preloader-oval.png" alt="preloader" class="video_preloader">

			<!-- metadata preload="none" -->
			<video class="video" preload="none" loop muted="true" autoplay="true" allow="autoplay" AutoplayAllowed AutoplayAllowlist fullscreen 
			v-on:mouseenter="videoPlay" v-on:mouseleave="videoPause" ref="videoRef"> 
				<source :src="videoSrc">
				<!-- {{ videoSrc }} -->
				Your browser does not support the video or formats.
			</video>

		</div>

	</div>
    
</template>

<script>
import src from "../controllers/components/VideoCtrl";
export default src;
</script>