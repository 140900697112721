
export default {
	name: "IHeader",
	data() {
		return {
			modalAgreement: false,
			active: false,
		};
	},
  mounted() {
		window.document.onscroll = () => {
			let $sectoin = document.getElementById('section_main');
			if(window.scrollY > $sectoin.offsetTop + $sectoin.clientHeight ){
				this.active = true;
			} else {
				this.active = false;
			}
		}
	},
  methods: {
    showModalSignUp() {
			this.$parent.$refs.modalSignUp.onShow(true);
			// console.log(this.$parent.$refs);
    },
  },
};