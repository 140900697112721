
export default {
  name: 'ITopliveCamsCtrl',
	data() {
		return {
			models: [
				{
					videoStar: true,
					name: 'Olga',
					watching: 730,
					age: 26,
					hd: true,
					live: true,
					country: `PL2`,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 10-15-14-636.mp4`),
					posterSrc: require(`@/assets/images/video-posters/Frame 5112.jpg`),
				},
				{
					videoStar: true,
					name: 'Daisy',
					watching: 437,
					age: 24,
					hd: true,
					live: true,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 10-26-08-052.mp4`),
					country: `PL`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5118.jpg`),
				},
				{
					videoStar: true,
					name: 'Nastusha ',
					watching: 789,
					age: 22,
					hd: true,
					live: true,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 10-32-45-765.mp4`),
					country: `UA`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5122.jpg`),
				},
				{
					videoStar: true,
					name: 'Karisha',
					watching: 380,
					age: 25,
					hd: true,
					live: true,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 10-38-36-300.mp4`),
					country: `PL`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5125.jpg`),
				},
			]
		}
	},
  methods: {
    showModalSignUp() {
			this.$parent.$refs.modalSignUp.onShow(true);
			// console.log(this.$parent.$refs);
			this.$parent.isshow=true;
    },
  },
}