<template>
			
	<header class="header" :class="{sticky:active}">
			
		<div class="header_top">
			
			<div class="header_logo">
				<a href="#0" @click.prevent="showModalSignUp()">
					<img src="@/assets/images/logo.png" alt="img"> 
				</a>
			</div>

			<div class="header_top_right">

				<ul class="header_list_chat">
					<li>
						<a href="#0" @click.prevent="showModalSignUp()">
							<img src="../assets/images/woman.png" alt="img" class="header_list_chat_img">
							<div>
								<span class="header_list_start_chat">Single girls <br>6114</span>
								<!-- <span class="header_list_start_online">online now: 335</span> -->
							</div>
						</a>
					</li>
					<li>
						<a href="#0" @click.prevent="showModalSignUp()">
							<img src="../assets/images/webcam.png" alt="img" class="header_list_chat_img">
							<div>
								<span class="header_list_start_chat">WITH WEBCAM: <br>140</span>
								<!-- <span class="header_list_start_online">WITH WEBCAM: 40</span> -->
							</div>
						</a>
					</li>
				</ul>

				<div class="header_login">

					<a href="#0" class="btn btn_orange" @click.prevent="showModalSignUp()">Sign Up</a>
				
				</div>

			</div>

		</div>

	</header>

</template>

<script>
import src from "../controllers/components/HeaderCtrl";
export default src;
</script>
