<template>

  <IVideo 
    v-for="(item, index) in models" :key="index"
    muted="muted" 
    :videoSrc="item.videoSrc" 
    v-model="item.videoSrc" 
    :videoHd="item.hd" 
    :videoLive="item.live" 
    :videoUsersWatching="item.watching" 
    :videoModelName="item.name" 
    :videoModelAge="item.age" 
    :videoModelCountry="item.country" 
    :videoPosterSrc="item.posterSrc" 
    :videoCardTitle="item.videoCardTitle" 
    :videoCardSubtitle="item.videoCardSubtitle" 
    :videoCardTxt="item.videoCardTxt" 
    :videoTypeCard="item.videoTypeCard"
    @click="showModalSignUp()"
  />

</template>

<script>
import src from "../controllers/components/NewCamsLadiesCtrl.js";
export default src;
</script>