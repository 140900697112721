import { createApp } from 'vue';
import App from '@/App.vue';

import router from '@/router';

import '@/assets/styles/video.scss';
import '@/assets/styles/video-grid.scss';

import '@/assets/styles/modal.scss';
import '@/assets/styles/modal-registration.scss';
import '@/assets/styles/modal-agreement.scss';


import '@/assets/styles/page-error.scss';

import '@/assets/styles/main.scss';
import '@/assets/styles/breakpoints.scss';


// Custom packages
import IHeader from '@/components/Header.vue';

import IVideo from '@/components/Video.vue';
import ITopliveCams from '@/components/TopliveCams.vue';
import INewCamsLadies from '@/components/NewCamsLadies.vue';

import IModal from '@/components/Modal.vue'; 

const app = createApp(App);

// Custom packages
app.component('IHeader', IHeader);

app.component('IVideo', IVideo);

app.component('ITopliveCams', ITopliveCams);

app.component('INewCamsLadies', INewCamsLadies);

app.component('IModal', IModal);

app.use(router);

app.mount('#app');