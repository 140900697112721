
export default {
  name: 'INewCamsLadies',
	data() {
		return {
			models: [
				{
					name: 'Christina',
					watching: 570,
					age: 24,
					hd: true,
					live: true,
					country: `PL`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5111.jpg`),
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 10-13-32-343.mp4`),
				},
				{
					name: 'Alyona',
					watching: 364,
					age: 23,
					hd: true,
					live: true,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 11-03-41-207.mp4`),
					country: `RU`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5136.jpg`),
				},
				{
					videoTypeCard: true,
					videoCardTitle: '50% DISCOUNT!',
					videoCardTxt: 'Best Rate Plan to Try -',
					videoCardSubtitle: '$4,88 ONLY!',
				},
				{
					name: 'Ivanka',
					watching: 380,
					age: 24,
					hd: true,
					live: true,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 11-17-06-223.mp4`),
					country: `PL`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5142.jpg`),
				},
				{
					name: 'Farisha',
					watching: 50,
					age: 583,
					hd: true,
					live: true,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 11-05-09-563.mp4`),
					country: `RU`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5137.jpg`),
				},
				{
					name: 'July',
					watching: 370,
					age: 22,
					hd: true,
					live: true,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 10-17-03-296.mp4`),
					country: `RU`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5113.jpg`),
				},
				{
					name: 'Vicky',
					watching: 119,
					age: 27,
					hd: true,
					live: true,
					country: `UA`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5107.jpg`),
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 10-10-04-856.mp4`),
				},
				{
					name: 'Irina',
					watching: 214,
					age: 29,
					hd: true,
					live: true,
					videoSrc: require(`@/assets/video/bandicam 2021-06-23 10-48-47-440.mp4`),
					country: `RU`,
					posterSrc: require(`@/assets/images/video-posters/Frame 5129.jpg`),
				},
			]
		}
	},
  methods: {
    showModalSignUp() {
			this.$parent.$refs.modalSignUp.onShow(true);
			// console.log(this.$parent.$refs);
    },
  },
}