<template>

  <IHeader />
  
  <section class="section_main" id="section_main">

    <div class="section_main_container">

      <div class="section_main_inner">

        <div class="section_main_desc">

          <h1 class="main_title">
            Premium Dating Site &
            <span class="color_green_light"> Best WebCam Chat Online </span>
          </h1>

          <p class="main_text">
            Here Ukrainian and Russian young single girls are looking for
            companions to enter into a serious relationship or just enjoy
            chatting with a webcam online.
          </p>

        </div>

			<form class="form_registration" @submit.prevent="onSubmit" novalidate="true"> 

				<div class="form_registration_logo">
					<img src="../../src/assets/images/logo.png" alt="logo" width="130"> 
				</div>

				<h2 class="form_registration_title">
					Join now for Free & 
					<span class="color_orange">
						Get Bonus!
					</span>
				</h2>
				
        <div class="field_wrapp">
          <input type="text" placeholder="Yor name" id="registration-name" class="field_input_user" @input="validateForm" @blur="validateForm" :class="{ error_field: errorNameStatus }" v-model="name">

          <div class="error" v-if="errorName">{{ errorName }}</div>
        </div>

        <div class="field_wrapp">
          <input type="email" placeholder="Email" id="registration-email" class="field_input_email" @input="validateForm" @blur="validateForm" :class="{ error_field: errorEmailStatus }" v-model="email">
          
          <div class="error" v-if="errorEmail">{{ errorEmail }}</div>
        </div>

        <div class="checkbox_wrapp">
          
          <div class="checkbox_box" @click="checkboxAgreement=!checkboxAgreement">
            <input type="checkbox" class="checkbox_field" id="checkbox" :checked="checkboxAgreement">
            <span class="checkbox_text">
                I agree with 
              <a href="#" @click.stop="hideModalSignUp(); modalAgreement = true;"> License agreement</a>
            </span>
            <span class="checkbox_checkmark"></span>
          </div>

          <div class="error" v-if="!checkboxAgreement">{{ errorCheckbox }}</div>
        </div>
				
				<button type="submit" class="btn btn_lg btn_block btn_orange" @click="validateForm" :disabled="errorEmailStatus || errorEmailStatus || !checkboxAgreement">Create an account</button>

			</form>

      </div>

    </div>

  </section>

  <section class="section_video">
    
    <h2 class="section_title">
      <img src="../../src/assets/images/icons/star-yellow.png" alt="star" />
      Top live cams
    </h2>

    <div class="video_grid_wrapp">
      <ITopliveCams/>
    </div>

    <hr class="divider"/>

    <h2 class="section_title">New ladies</h2>

    <div class="video_grid_wrapp">
      <INewCamsLadies/>
    </div>

  </section>

	<IModal :show="modalSignUp" @close="modalSignUp = false;" class="modal-registration" ref="modalSignUp">
		<template v-slot:content>
			
			<form class="form_registration" @submit.prevent="onSubmitModal" novalidate="true"> 

				<div class="form_registration_logo">
					<img src="../../src/assets/images/logo.png" alt="logo" width="130"> 
				</div>

				<h2 class="form_registration_title">
					Join now for Free & 
					<span class="color_orange">
						Get Bonus!
					</span>
				</h2>
				
        <div class="field_wrapp">
          <input type="text" placeholder="Yor name" id="registration-name" class="field_input_user" @input="validateFormModal" @blur="validateFormModal" :class="{ error_field: errorNameStatusModal }" v-model="nameModal">

          <div class="error" v-if="errorNameModal">{{ errorNameModal }}</div>
        </div>

        <div class="field_wrapp">
          <input type="email" placeholder="Email" id="registration-email" class="field_input_email" @input="validateFormModal" @blur="validateFormModal" :class="{ error_field: errorEmailStatusModal }" v-model="emailModal">
          
          <div class="error" v-if="errorEmailModal">{{ errorEmailModal }}</div>
        </div>

        <div class="checkbox_wrapp">
          
          <div class="checkbox_box" @click="checkboxAgreementModal=!checkboxAgreementModal">
            <input type="checkbox" class="checkbox_field" id="checkbox" :checked="checkboxAgreementModal">
            <span class="checkbox_text">
                I agree with 
              <a href="#0" @click.stop="hideModalSignUp(); modalAgreement = true;"> License agreement</a>
            </span>
            <span class="checkbox_checkmark"></span>
          </div>

          <div class="error" v-if="!checkboxAgreementModal">{{ errorCheckboxModal }}</div>
        </div>

				<button type="submit" class="btn btn_lg btn_block btn_orange" @click="validateFormModal" :disabled="errorEmailStatusModal || errorEmailStatusModal || !checkboxAgreementModal">Create an account</button>

			</form>

		</template>
	</IModal>
    
  <IModal :show="modalAgreement" @close="modalAgreement = false" class="modal-agreement" ref="modalAgreement">
    <template v-slot:content>
        
      <div class="modal-header">
        Terms of use
      </div>

      <div class="modal-body">
        <p style="text-align: justify;">This Agreement establishes the legally binding terms of your use of the Services provided by Victoriyaclub.com. By completing the subscription process you are indicating that you agree to be bound by all of the terms of this Agreement. If you do not agree to this Agreement, please do not register for the site victoriyaclub.com. This Agreement is subject to change at any time, and changes are effective upon them being posted on the www.victoriyaclub.com (hereafter referred to as the Site). You, as a member of the Site will receive a notifications of all the changes in the Agreement. Visitors can see the changes on this page. We reserve the right at any time to modify or amend the terms of this Agreement, modify content, functionality of the Site, pricing, or to change / charge any fees for using the Site and the Services. Your use of the Site from the date of introduction of amendments and additions deemed your consent to such changes. If you do not agree with any amendments or changes in this Agreement, please do not use the Site. You can track the changes on this page. This agreement includes Privacy Policy, Refund Policy, a subscription to the site notifications, and periodic services and promotions.</p>

        <p style="text-align: justify;"><strong>1.Eligibility and General Restrictions</strong></p>
        <p style="text-align: justify;">By using or viewing the site, you represent and warrant that:</p>
        <p style="text-align: justify;">1.1 You are at least 18 years of age;</p>
        <p style="text-align: justify;">1.2 You are interested in finding a girl-friend from Ukraine, Russia or other countries.</p>
        <p style="text-align: justify;">1.3 It is lawful for you to view the type of content provided by Victoriyaclub.com where you are viewing the site;</p>
        <p style="text-align: justify;">You may not access the Site or utilize our Services:</p>
        <p style="text-align: justify;">1.5 If you are a convicted sex offender;</p>
        <p style="text-align: justify;">1.6 If you have a felony conviction or the equivalent in any jurisdiction;</p>
        <p style="text-align: justify;">1.7 If you have been convicted of any crime of moral turpitude; or</p>
        <p style="text-align: justify;">1.8 If you are not a natural person utilizing our Services solely for your own personal use and in good faith</p>
        <p style="text-align: justify;">1.9 You will not breach any of the terms and conditions of this agreement;</p>
        
        <p style="text-align: justify;"><strong>2.Registration and authorization</strong></p>
        <p style="text-align: justify;">2.1 Before registration, you can read the rules of the site and read answers to frequently asked questions.</p>
        <p style="text-align: justify;">2.2 When you register, you must complete the minimum information about you. In order other members of the Site could understand your intentions and goals, we recommend filling in all the information about you in the form on Victoriyaclub.com</p>
        <p style="text-align: justify;">2.3 Please, note that you must enter a valid email address because you will get a massage from us with instructions on activating your account.&nbsp;</p>
        <p style="text-align: justify;">2.4 When you register to become a member, you agree to provide accurate and complete information about yourself as well as update it as necessary. We reserve the right at any time to terminate your membership if you are found to provide inaccurate, outdated, incomplete or misleading information. We can ask you for any additional information if it is necessary. The evaluation of the information is based on the sole personal opinion of the Site administration.</p>
        <p style="text-align: justify;">2.5 To gain access to the Site you must complete a simple registration procedure to obtain an account and a password. The account will be open when you complete the registration, confirm that your e-mail address is real, confirm the safety of your registration data, and confirm that you agree to be bound by all the terms and conditions of the Agreement.&nbsp;</p>
        <p style="text-align: justify;">2.6 By becoming a member, you agree to receive information by e-mail initiated by victoriyaclub.com without limitation. Such mailing may include e-mail notifications about new members of the site, lists of updates, messages informing you of promotions, discounts etc. You can decline our mailing to your e-mail without any explanation by choosing the next option on your personal page.</p>
        
        <p style="text-align: justify;"><strong>3.Cancellation right&nbsp;&nbsp; </strong></p>
        <p style="text-align: justify;">3.1 We reserve the right to immediately terminate your membership if you breach one or more provisions of the Agreement. We reserve the right to take further actions if your violation triggered our financial and moral losses or losses of other members of our website. You will not be entitled to a refund of any used and / or unused funds in case of any breach of this Agreement.</p>
        <p style="text-align: justify;">3.2 You may terminate your membership at any time for any reason. Running this procedure is carried out by the site administration upon your written or email request for termination. Any unused funds may be paid back to you in accordance with the provisions of "Refund Policy".</p>
        <p style="text-align: justify;">3.3 We charge for the range of dating services we provide and do not refund money if the Site Services are disabled or upgraded. We reserve the right to discontinue the Site at any time without giving reasons and explanations.</p>
        <p style="text-align: justify;">3.4 If you cancel your membership on the Site, your profile will be removed. You will be able to restore your profile in the course of 3 years from the cancellation. To restore your profile, login to your account and resume the use of the Site Services. If a technical restoration is not available you will be recommended to re-register.</p>
        <p style="text-align: justify;">3.5.&nbsp;In case if the using of our website was terminated, that means You are not our customer any more, any of your complaints will not be considered.</p>
        
        <p style="text-align: justify;"><strong>4.</strong><strong> Member warranties and undertakings.</strong></p>
        <p style="text-align: justify;">By completing the registration process for Victoriyaclub.com you hereby agree to</p>
        <p style="text-align: justify;">4.1 have only one account of membership on this Site, and to use it personally. You cannot allow access to others to use your membership. Presence on the Site is allowed only to private individuals and is not allowed for public organizations, agencies, and informal associations</p>
        <p style="text-align: justify;">4.2 be honest and serious in dialogues and cooperation with other Members of the Site in order to establish relations.</p>
        <p style="text-align: justify;">4.3 use the Site and its Services within any applicable laws and regulations. You will not transmit defamatory, inaccurate, abusive, obscene, profane, offensive, sexually oriented, threatening, abusive, racist or unlawful material or any material that infringes or violates another party's rights when using the Site and the Services. Breaching of any rule gives us right to terminate your membership and file a complaint about you to the law-enforcement authorities of your country.</p>
        <p style="text-align: justify;">4.4 not use the Site and its Services in any illegal and / or unauthorized way. You will not perform illegal and / or unauthorized access to our Site and its Services, collect usernames and / or email addresses of other men and / or ladies posted on this site by electronic or other means for hacking websites. You will not engage in sending unsolicited e-mails and / or links to other sites on behalf of our customers, with reference to us in any way, that qualifies as SPAM. We reserve the right to control and to take legal action, including civil, criminal and judicial redress against you in case of your breach of this provision.</p>
        <p style="text-align: justify;">4.5 We are not responsible for the conduct, whether online or offline, of the ladies listed on this site. Please use caution and common sense when using the Site and the Services. Your interactions with females, whose profiles are posted on this Site, are solely at your own risk.</p>
        <p style="text-align: justify;">4.6 You may not publish content that directly or indirectly alludes to child pornography or attempts to solicit communication with or on behalf of any person under the age of 18.</p>
        <p style="text-align: justify;">4.7 Pornography and Obscenity: We do not allow image or video content that contains nudity, sexually graphic material.</p>
        <p style="text-align: justify;">4.8 Paedophilia, Incest and Bestiality: customers may not publish written, image or video content that promotes paedophilia, incest and bestiality.</p>
        <p style="text-align: justify;">4.9 Child Pornography: victoriyaclub.com has a zero-tolerance policy against child pornography, and we will terminate and report to the appropriate authorities any user who publishes or distributes child pornography.</p>
        <p style="text-align: justify;">We will immediately without notice terminate your member account and close you out from our website.</p>
        <p style="text-align: justify;">4.10 You may not post, distribute, or reproduce in any way any copyrighted material, trademarks, or other proprietary information without obtaining the prior written consent of the Site Administration.</p>
        <p style="text-align: justify;">4.11 We reserve the right to review and delete any content in your profile, messages, photos, which in our opinion violates the Agreement or which may be offensive, illegal, violate the rights of other members, harm, or threaten the safety of the Site and the Services.</p>
        <p style="text-align: justify;">4.12 Fixed charges on this Site are an integral part of this Agreement. We reserve the right to change charges for the Services at any time. If you are unhappy with any changes in the Service charges you may terminate your membership by writing to us.</p>
        <p style="text-align: justify;">4.13 Upon the termination of your membership, you agree that you will not have access to all the Site Services from the date of termination and you will not have any claims if further communication with a lady from our website fails.</p>
        
        <p style="text-align: justify;"><strong>5. Member services. </strong></p>
        <p style="text-align: justify;">5.1 You acknowledge and agree that the form and nature of the Services provided by Victoriyaclub.com &nbsp;may change from time to time without prior notice. You agree to pay for the specified Site Services, such as sending mail, live chat and videochat time . You acknowledge and agree that your account credits are charged correctly. We may change or discontinue any Services or features at any time and without notice. We can set new rules for Services existing on the Site.</p>
        <p style="text-align: justify;">5.2 You agree to pay us the full cost of paid services that are used from your account, regardless of whether they were used by you or any other person, with or without your consent.</p>
        <p style="text-align: justify;">5.3 Mail</p>
        <p style="text-align: justify;">You agree that:</p>
        <p style="text-align: justify;">5.3.1 You clearly know the way our e-mail Service functions and are informed of the charge for this Service.</p>
        <p style="text-align: justify;">5.3.2 When you send an e-mail message, the credits will be withdrawn from your account automatically in accordance with the tariff plan for paid services. If you have a suspicion of spam one or more members of the site send you, you can file a complaint to our User Support service.</p>
        <p style="text-align: justify;">5.3.3 You will not transmit defamatory, inaccurate, abusive, obscene, profane, offensive, sexually oriented, threatening, abusive, racist or illegal material or any material that violates another party's rights when using our e-mail Service.</p>
        <p style="text-align: justify;">5.3.4 You will use the Site Services within any applicable laws and regulations.</p>
        <p style="text-align: justify;">5.3.5 If you want to develop friendship, not long-terms relations, with a lady on our site, please make your intentions as clear as possible.&nbsp;&nbsp;</p>
        
        <p style="text-align: justify;"><strong>6. Payment Policy. </strong></p>
        <p style="text-align: justify;">6.1 An online internal payment unit on the Site is called a Credit</p>
        <p style="text-align: justify;">6.2 You pay for certain services on our Site in credits. Victoriyaclub.com allows you to purchase credits by debiting your credit or debit card in accordance with the available package of credits and adopted method of payment. If you do not have any credit card you can inform us about this and state the most convenient way of payment. If you do not have an opportunity at this time to pay for the purchase of credits, please, contact us. We are always ready to compromise.</p>
        <p style="text-align: justify;">6.3 To order the Site Services you must have a positive credit balance. Users with a negative credit balance or bonus (trial) credits remain limited in receiving services.</p>
        <p style="text-align: justify;">6.4 Credits can be used any time.</p>
        <p style="text-align: justify;">6.5 Prices for a credit package may be changed at any time without prior notice. To get the current price for each credit go to a certain area of account settings. New prices will be applied to your next purchase of credits after the adjustments.</p>
        <p style="text-align: justify;">6.6 If you continue to use the Services after the price adjustment, it means your consent to new prices. If a price for a credit package is reduced, you do not have the opportunity to get a refund or additional credits for credits previously purchased at a higher price.</p>
        <p style="text-align: justify;">6.7 You can at any time follow the flow of your credits on your personal page in Order history.</p>
        <p style="text-align: justify;">6.8 You must pay for all the Site Services you received. You are entitled to a refund if the service you have purchased has been obtained in an improper manner. We reserve the right to offset the foregone services by crediting assets to your account.</p>
        <p style="text-align: justify;">6.9 You can order a service that is within your means. If there are not enough credits on your account you cannot purchase a service that costs more than the number of credits you have.</p>
        <p style="text-align: justify;">6. 10 In case you think that Victoriyaclub.com charged off your credits by mistake you will be able to file a complaint to our Support Service within 90 days from the incident .</p>
        <p style="text-align: justify;">6.11 Victoriyaclub.com reserves the right to stop accepting your debit or credit card.</p>
        
        <p style="text-align: justify;"><strong>7.Privacy Policy </strong></p>
        <p style="text-align: justify;">Victoriyaclub.com&nbsp; shares your concerns about the protection of your personal information online. This Privacy Policy ("Policy") describes our practices regarding the collection and use of information through our website, located at www.victoriyaclub.com. By using the Site or obtaining any product or service through this Site, you agree to the collection and use of information as set forth in this Policy. If you do not agree to this Policy, please do not use the Site.</p>
        <p style="text-align: justify;">We may, from time-to-time, update this Policy. We will notify you of changes to the Policy by posting the updated Policy on this page. We ask that you bookmark and periodically review this page to ensure continuing familiarity with the most current version of the Policy. You can determine when this Policy was last revised by checking the legend at the bottom of the Policy.</p>
        
        <p style="text-align: justify;"><strong>8. Refund policy </strong></p>
        <p style="text-align: justify;">8.1 Typically, the payment received from you cannot be returned if there is no reason for this according to our refund policy. Refund Policy on this Site is a part of this Agreement. We reserve the right to change or modify the Refund Policy at any time. All changes are effective upon posting.</p>
        <p style="text-align: justify;">8.2 You can be refunded your money only in cases stipulated by this agreement. You agree that the Site administration is not obliged to refund you for any purchased credits if the Services have been properly and fully provided to you.</p>
        <p style="text-align: justify;">8.3 The Site is not obliged to refund in case of your failure to develop a relationship on the website.</p>
        <p style="text-align: justify;">8.4 The site policy for refunding is determined by your consent with following provisions.</p>
        <p style="text-align: justify;">8.4.1 You do not have the right for refunding if you are only discontent with the Site or any of Services offered through the site.</p>
        <p style="text-align: justify;">8.4.2 Messages sent to a lady on the site, which remain unread or unanswered are not eligible for a refund of credits.</p>
        <p style="text-align: justify;">8.4.3 Bonus credits credited to your billing account cannot be refunded.</p>
        <p style="text-align: justify;">8.4.4 When you send an email message and it is forwarded to a recipient's mailbox, this means that a message is formatted correctly and thus you are not entitled to a refund. This includes but is not limited to, cases such as accidentally sending duplicate emails or a chat session that accidentally has not been stopped.</p>
        <p style="text-align: justify;">8.4.5 The Site Administration reserves the right to offer you to replace a refund by charging on your account bonus credits.</p>
        <p style="text-align: justify;">8.4.6 The Site is not responsible for the loss of credits in case you have not learnt all the potentialities of the Site and as a result have not use the Services properly.</p>
        <p style="text-align: justify;">8.5 In all other cases, the you are encouraged to report to the administration of your claim and the grounds for repayment. Consideration of claims by the Site administration takes place within 7 days with the exception of weekends. The Site administration reserves the right to request additional information about the claim, and the proof of its eligibility.</p>
        <p style="text-align: justify;">8.6 The Site administration reserves the right to demand compensation for losses and expenses incurred in connection with your actions on the site and take any legal action available for this.</p>
        <p style="text-align: justify;">8.7 You are entitled to a refund to your account if the Services you paid for have not been provided to you by our fault, apart from those that failed due to technical problems of equipment, servers, Internet channels and other equipment not owned by Victoriyaclub.com.</p>
        <p style="text-align: justify;">8.8 You have the right to a refund if you adduced an evidence of unsatisfactory services.</p>
        
        <p style="text-align: justify;"><strong>9.</strong><strong> Property rights </strong></p>
        <p style="text-align: justify;">9.1 The Victoriyaclub.com defends the rights of their property and ownership of the software and Services of the Site. All Online Materials on the Victoriyaclub.com site, including, without limitation, text, software, names, logos, trademarks, service marks, trade names, and other proprietary information of ownership, addresses, business licenses, images, profiles of ladies, photos, illustrations, audio clips, video clips, and music are copyrighted intellectual property. All usage rights are owned and controlled by Victoriyaclub.com, except where stated otherwise.</p>
        <p style="text-align: justify;">Except for that information which is in the public domain or for which you have given permission, you may not copy, modify, publish, transmit, distribute, display, or sell any such proprietary information.</p>
        <p style="text-align: justify;">9.2 It is not allowed to copy the code, screenshots of any part of the Site without our prior written permission. You should also not use any metatags, codes or other devices containing any reference to this Site or the Services to direct any person to other web sites for any purpose.</p>
        
        <p style="text-align: justify;"><strong>10.</strong><strong> Limitations and exclusions of liability</strong></p>
        <p style="text-align: justify;">10.1 Victoriyaclub.com is not RESPONSIBLE FOR THE CONDUCT , whether online or offline, of the members listed on this site. Please use caution and common sense when using the Site and the Services. Your interactions with members, whose profiles posted on this site, are solely AT YOUR OWN RISK.</p>
        <p style="text-align: justify;">10.2 All profiles and photos of ladies on this Site are provided "as is" by third party branches in different cities of different countries. We cannot carry out a thorough investigation of the reliability of information about the female profiles posted on this Site and / or the women backgrounds, morality and behavior. Use of the Site and the Services, and your interaction with those of women members is completely at your own risk.</p>
        <p style="text-align: justify;">10.3 The Site activity includes cooperation with third parties. The third party is agencies which we work with but we cannot control agencies themselves, and their individual employees, so some problem may occur. Your caution is needed when using the Site and the Services. If you suspect improper activity of some ladies or our Services, you can let us know promptly, so that we can carry out an immediate investigation.</p>
        <p style="text-align: justify;">10.4 We cannot guarantee and does not promise any specific results from the use of the Site and / or Services. We make no warranties or representations in respect of any advice, opinion, statement or other information uploaded or distributed through the site from us, our partners or any member or any other person or entity.</p>
        <p style="text-align: justify;">10.5 We claim no responsibility for the quality of the Services and do not accept any liability for any loss or damage, including personal injury or death, resulting from your use of the services. You are entirely responsible for any transactions or agreements , whether online or offline, between you and the ladies members of the Site</p>
        <p style="text-align: justify;">10.6 This Site has a high level of success. It has gained a good reputation, which is widely recognized. Our members want to build lasting relationships. However, some other sites of the same type can illegally duplicate our business and service mode, publish false profiles and commit service fraud to cheat honest members who are serious about finding a woman for a long-term relationship. Victoriyaclub.com administration assumes no liability for any indirect, consequential, incidental, special or punitive damages, including lost profits, arising from the use of profiles of our ladies on other Web sites.</p>
        <p style="text-align: justify;">10.7 The site does not have any influence on a lady, you communicate or have a meeting with. A prospective meeting fully depends on mutual understanding between you and a woman, and sometimes a representative of the agency. In the case of disclosing any fraud, the liability is assumed by the third party-a local dating agency.</p>
        <p style="text-align: justify;">10.8 Victoriyaclub.com is solely responsible for own Services only within the law, and should not be held responsible for the content and services provided by third parties - namely, for the dating agencies in Ukraine, Russia and Europe. The Site cooperates with them, yet does not accept any debts, obligations, third party claims. The Site cooperates with dating agencies only under an agreements accepted by a third party.</p>
        <p style="text-align: justify;">10.9 Except where such provisions are restricted (and in that event liability is disclaimed to the fullest extent permitted by law), in no event will Victoriyaclub.com be liable to you or any third person for any direct, indirect, consequential, exemplary, incidental, special or punitive damages including lost profits or personal injury arising from your use of the site or of the Victoriyaclub.com service. We accept no responsibility and thus accept no claims, losses, liabilities, expenses (including attorneys' fees) or other expenses that arise directly or indirectly from violation of this Agreement.</p>
        <p style="text-align: justify;">10.10 The site administration and the owner is not responsible for the privacy policy of a THIRD PARTY- local agencies we cooperate with. We do not specify what information will be collected from a third party and whether it will be used only for the Site Services.</p>
        
        <p style="text-align: justify;"><strong>11. Miscellaneous and Risk Warning Notice</strong></p>
        <p style="text-align: justify;">11.1 Spam complaints</p>
        <p style="text-align: justify;">Victoriyaclub.com denies Spam in any form. If you received a spam message into your forum, mailbox, or guest book, please email us and we will immediately take action. Make sure you send us the text of a message with its link.</p>
        <p style="text-align: justify;">11.2 Contact information</p>
        <p style="text-align: justify;">Victoriyaclub. com serves as a platform for communication between members.</p>
        <p style="text-align: justify;">11.2.1 Contact information of our ladies provided to gentlemen is received from local agencies "as it is", as it was reported on Victoriyaclub.com through the third party.</p>
        <p style="text-align: justify;">11.2.2 Transferring of contact information from the agency to you may occur only with the written consent of the lady, given to the agency. You are granted the right to receive the contact information after you have spent on the Site ... number of credits (see tarrifs).</p>
        <p style="text-align: justify;">11.2.3 If as a result of communicating with a lady on our website you have got her contact information either from us or from other sources, you agree to continue to communicate in any way convenient to you not filing a claim to the Site. You acknowledge and agree that you will not demand repayment or return the value of Services you paid when communicating with the lady on the Site.</p>
        <p style="text-align: justify;">11.2.4 The Site reserves the right to examine any differences, if any, between the contact information in electronic form, comparing the image copies of supporting documents such as identity cards, handwritten forms, etc. In no case shall Victoriyaclub.com verify the information in any other way. Consequently, there are always risks of getting incomplete, inaccurate or misleading information.</p>
        <p style="text-align: justify;">11.2.5 Victoriyaclub.com is not responsible for any incorrect or inaccurate contact information provided to our members as it is given to us by third party. If such problems occur, Victoriyaclub.com, founders, &nbsp;directors, managers and employees can not be held responsible for loss / damages (if any) associated with the actions of the third party in the provision of information and their compliance. Our participation in the Services is not complete, signed as a member you agree with this situation and assume all the risks.</p>
        <p style="text-align: justify;">11.4 Communication between the site and service providers.</p>
        <p style="text-align: justify;">11.4.1 Victoriyaclub.com and service providers ( such as local agencies) are completely independent of each other. Service provider is not acting as an agent and does not represent the interests of members. Service provider is not a management company or a subsidiary of Victoriyaclub.com.</p>
        <p style="text-align: justify;">11.4.2 We never allow our partners (local agencies) which cooperate with us to use our name and profiles posted on our website as an excuse for additional charging our customers other than for the Services we provide. All these facts violate the terms of collaboration between the Site and service providers.</p>
        
        <p style="text-align: justify;"><strong>12.&nbsp; International Marriage Broker Regulation Act</strong></p>
        <p style="text-align: justify;">If you are a U.S. citizen or resident seeking to obtain personal information about or meet personally with a non-U.S. citizens or residents, you hereby agree to fully comply with all provisions of the IMBRA, including the requirement that you not make direct contact with the non-U.S. citizens or residents until you have completed the procedures required by the IMBRA.</p>
        <p style="text-align: justify;">You understand and hereby agree that all use of our Services by you is conditioned upon your continuing full compliance with the IMBRA and particularly with the information notification and release requirements.</p>
        <p style="text-align: justify;">Once you have fulfilled all IMBRA related requirements, we may in our sole discretion put you in contact with a Facilitator to assist with background information notification and release requirements so that you may eventually directly contact non-U.S. citizens or residents.</p>
        <p style="text-align: justify;">The Facilitator provides us with, and we maintain, secure electronic copies of all information, forms and documents you and the non-U.S. citizen have given the Facilitator for the purpose of complying with IMGRA requirements.</p>
        <p style="text-align: justify;"><strong>WARNING</strong></p>
        <p style="text-align: justify;">The above situation show the risks associated with using Victoriyaclub.com. We are making efforts to improve and within reasonable limits and the possibilities, taking precautionary measures to manage these risks to which members may be vulnerable, there is, of course, situations falling beyond our control. We do our best to protect our customers from these risks, and we look forward to the feedback of members in this regard.</p>
      </div>

    </template>
  </IModal>

</template>

<script>

import axios from "axios";

export default {
  data(){
    return{
      modalAgreement: false,
      modalSignUp: false,

			sUserIP: window.userIP || '',

			nameModal: '',
      emailModal: '',
      
      submitedModal: false,
      checkboxAgreementModal: true,

      errorNameStatusModal: false,
      errorEmailStatusModal: false,

      errorNameModal: '',
      errorEmailModal: '',
      errorCheckboxModal: 'This field is required.',
      
			// static
      name: '',
      email: '',
      
      submited: false,
      checkboxAgreement: true,

      errorNameStatus: false,
      errorEmailStatus: false,

      errorName: '',
      errorEmail: '',
      errorCheckbox: 'This field is required.',
    }
  },
	created: async function (){
		await this.sendPidAndSid();
	},
  methods: {
    hideModalSignUp() {
			this.$refs.modalSignUp.onClose(true);
			// console.log(this.$parent.$refs);
    },
		
		sendPidAndSid: async function () {
			if (process.env.NODE_ENV === 'development') return;

			let params = new URL(document.location).searchParams;
			let data = 'pid=' + params.get("pid") + '&sid=' + params.get("sid") + '&referer=' + document.referrer;

			let options = {
				method: 'post',
				url: '/api/?action=send_partner_info',
				data: data
			};

			try {
				await axios(options);
			}
			catch (e) {
				console.error(e);

				return e;
			}

			console.log("Success send pid/sid");
		},

		validateFormModal: function () {
      
      if (!this.nameModal) {
        this.errorNameStatusModal = true;
        this.errorNameModal = 'This field is required.';
      }
      else if (this.nameModal.length <= 2){
        this.errorNameStatusModal = true;
        this.errorNameModal = 'Please enter at least 3 characters.';
      } 
      else {
        this.errorNameStatusModal = false;
        this.errorNameModal = false;
      }
     
      if (!this.emailModal) {
        this.errorEmailStatusModal = true;
        this.errorEmailModal = 'This field is required.';
      }
      else if (this.emailModal.length <= 5){
        this.errorEmailStatusModal = true;
        this.errorEmailModal = 'Please enter at least 6 characters.';
      } 
      else if (!this.validEmail(this.emailModal)) {
        this.errorEmailStatusModal = true;
        this.errorEmailModal = 'Please, enter a valid email';
      }
      else {
        this.errorEmailStatusModal = false;
        this.errorEmailModal = false;
      }

      if (this.nameModal && this.emailModal && this.checkboxAgreementModal) {
        this.submitedModal = true;
      }
      else {
        this.submitedModal = false
      }
    },
    onSubmitModal: async function () {

      if (this.submitedModal) {
      
        let formData = 'name=' + this.nameModal + '&email=' + this.emailModal +'&sign_agree=' + this.checkboxAgreementModal;

        if (!this.sUserIP) {
          window.userIP = this.sUserIP = await this.getIP();
        }

        let oSearchParams = new URL(document.location).searchParams;

        formData += '&ip=' + this.sUserIP + '&pid=' + oSearchParams.get('pid') + '&pid_sid=' + oSearchParams.get('sid');

        try {
          let oResp = await axios.post('/api/?action=registration', formData);

          if (oResp.data.error && oResp.data.error !== '') {

            alert(oResp.data.error);
          }
          else if (oResp.data.loc) {
            setTimeout(function(){
              document.location = oResp.data.loc;
            }, 500);
          }
        }
        catch (e) {
          console.error(e);
        }
      }
    },

		// static
		validateForm: function () {
      
      if (!this.name) {
        this.errorNameStatus = true;
        this.errorName = 'This field is required.';
      }
      else if (this.name.length <= 2){
        this.errorNameStatus = true;
        this.errorName = 'Please enter at least 3 characters.';
      } 
      else {
        this.errorNameStatus = false;
        this.errorName = false;
      }
     
      if (!this.email) {
        this.errorEmailStatus = true;
        this.errorEmail = 'This field is required.';
      }
      else if (this.email.length <= 5){
        this.errorEmailStatus = true;
        this.errorEmail = 'Please enter at least 6 characters.';
      } 
      else if (!this.validEmail(this.email)) {
        this.errorEmailStatus = true;
        this.errorEmail = 'Please, enter a valid email';
      }
      else {
        this.errorEmailStatus = false;
        this.errorEmail = false;
      }

      if (this.name && this.email && this.checkboxAgreement) {
        this.submited = true;
      }
      else {
        this.submited = false
      }
    },
    validEmail: function (email) {
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    onSubmit: async function () {

      if (this.submited) {

        let formData = 'name=' + this.name + '&email=' + this.email +'&sign_agree=' + this.checkboxAgreement;

        if (!this.sUserIP) {
          window.userIP = this.sUserIP = await this.getIP();
        }

        let oSearchParams = new URL(document.location).searchParams;

        formData += '&ip=' + this.sUserIP + '&pid=' + oSearchParams.get('pid') + '&pid_sid=' + oSearchParams.get('sid');

        try {
          let oResp = await axios.post('/api/?action=registration', formData);

          if (oResp.data.error && oResp.data.error !== '') {

            alert(oResp.data.error);
          }
          else if (oResp.data.loc) {
            setTimeout(function(){
              document.location = oResp.data.loc;
            }, 500);
          }
        }
        catch (e) {
          console.error(e);
        }
      }
    },
		getIP: async function () {
			try {
				let oResp = await axios('https://api.ipify.org?format=json');
				return oResp.data.ip;
			}
			catch (e) {
				console.error(e);
				return e;
			}
		}
  }
}
</script>
