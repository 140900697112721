<template>
  <IVideo
    muted="muted"
    v-for="(item, index) in models"
    :key="index"
    :videoSrc="item.videoSrc"
    v-model="item.videoSrc"
    :videoHd="item.hd"
    :videoLive="item.live"
    :videoUsersWatching="item.watching"
    :videoModelName="item.name"
    :videoModelAge="item.age"
    :videoModelCountry="item.country"
    :videoPosterSrc="item.posterSrc"
    :videoStar="true"
    @click="showModalSignUp()"
  />
</template>

<script>
import src from "../controllers/components/TopliveCamsCtrl.js";
export default src;
</script>