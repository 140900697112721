
export default {
  name: 'IVideo',
  props: {
    videoSrc: String,
    videoPosterSrc: String,
		videoHd: Boolean,
		videoStar: {
			type: Boolean,
			default: false
		},
		videoLive: Boolean,
		videoUsersWatching: Number,
		videoModelName: String,
		videoModelAge: Number,
		videoModelCountry: String,
		videoTypeCard: {
      type: Boolean,
			default: false,
    },
		videoCardTitle: {
			type: String,
			default: '',
		},
		videoCardSubtitle: {
			type: String,
			default: '',
		},
		videoCardTxt: {
			type: String,
			default: '',
		}
  },
	methods:{
		videoPlay() {
			this.$refs.videoRef.load();
			if(!this.$refs.videoRef.load) {
				this.$refs.videoRef.load();
			}
			if(this.$refs.videoRef.load) {
				this.$refs.videoRef.play();
			}
			this.isActive = !this.isActive;
		},
		videoPause() {
			if(this.$refs.videoRef.play) {
				this.$refs.videoRef.pause();
			}
		},
	},
	data() {
    return {
			isActive: false,
    }
  },
}